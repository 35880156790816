<template>
  <div class="order-page">
    <div class="addressContent orderAddress"
    :class="{ 'no-address': !addressList }"
      @click="backAction">
      <div v-if="addressList">
        <div class="line1">
          <div class="name">{{ addressList.receiptname }}</div>
          <div class="tel">{{ addressList.call }}</div>
        </div>
        <div class="line2" v-html="addressActioned(addressList)"></div>
        <div class="orderAddressArrow">
          <img src="./img/arrow.png">
        </div>
      </div>
      <div v-else class="address-write">请填写收货地址
        <div class="orderAddressArrow">
          <img src="./img/arrow.png">
        </div>
      </div>
    </div>
    <div class="orderContent">
      <div class="order-imgs">
        <div class="orderImg">
					<img :src="thumbUrl">
				</div>
				<div class="orderText">
					<p class="orderYY">[有鱼定制]</p>
					<p class="orderTitle">{{ goodsDetails.goodsName }}</p>
				</div>
        <div class="detailContent">
          <p>
            <span>
              价格:
              <span class="originPrice">{{ goodsDetails.goldPrice }}</span>金豆
            </span>
            <span> 市场价 <span class="originPrice">{{ goodsDetails.originPrice }}</span>元</span>
          </p>
        </div>
      </div>
		</div>
    <div class="orderTip">
      <div class="orderTipImg">
        <img src="./img/orderTip.png">
      </div>
      <span>订单提交后不可编辑哦，若有疑问请联系在线客服</span>
    </div>
    <div class="btn" :class="{ 'default': isLoading }"
      v-if="!isSubmit"
      @click="saveAddressAction">{{ isLoading ? '加载中...' : '确认订单' }}</div>
    <!-- <div class="btn default" v-else @click="jumpRecord">已兑换, 请至"兑换记录"查看</div> -->
    <div class="fixed" v-if="isOpenAddress">
      <y-address @child-open="childOpen"></y-address>
    </div>
  </div>
</template>
<script>
import { getAppStatus, callAppHandler } from '@/utils/app';
import { getPreSaveWlInfo, getSaveWinnerAddress, getQueryGoodsDetail } from '@/api/base';
import yAddress from './address';

export default {
  name: 'GoldOrder',
  data() {
    return {
      thumbUrl: '',
      isLoading: true,
      goodsDetails: {},
      addressList: {},
      isOpenAddress: false,
      cuserid: getAppStatus().cuserId,
    };
  },
  components: { yAddress },
  computed: {
    cuserids() {
      // return '6d1455cb-cdbb-46c4-911d-8a60a0342fe9';
      // return 'df0fdeaa-0f72-44d1-90cd-783d6768b322';
      return getAppStatus().cuserId;
    },
    from() {
      const { from } = this.$route.query;
      return from;
    },
    gerId() {
      const { gerId } = this.$route.query;
      return gerId;
    },
    prizeId() {
      const { prizeId } = this.$route.query;
      return prizeId;
    },
    mid() {
      const { mid } = this.$route.query;
      return mid;
    },
    goodsId() {
      const { goodsId } = this.$route.query;
      return goodsId;
    },
    isSubmit() {
      return sessionStorage.getItem('saveAddress');
    },
  },
  mounted() {
    document.title = '订单详情';
    this.cuserId = this.cuserId || sessionStorage.getItem('userId');
    this.cuserid && this.initData();
    callAppHandler('jzUserId', null, (res) => {
      sessionStorage.setItem('userId', res);
      this.cuserid = res;
      this.initData();
    });
  },
  methods: {
    backAction() {
      const { prizeId, mid, gerId } = this;
      let query = {};
      if (mid) {
        query = prizeId ? { from: 'prize', prizeId }
          : { from: 'record', gerId };
      }
      const params = this.$route.query;
      query = { ...query, ...params };
      // this.$router.push({
      //   path: 'address',
      //   query,
      // });
      this.isOpenAddress = true;
    },
    initData() {
      sessionStorage.setItem('userId', this.cuserid);
      const { thumbUrl } = this.$route.query;
      this.thumbUrl = thumbUrl;
      const isTimes = sessionStorage.getItem('isTimes');
      !isTimes && this.preSaveWlInfoAction();
      this.isLoading = !isTimes;
      let addressList = sessionStorage.getItem('addressList');
      this.addressList = JSON.parse(addressList);
      this.getDetailsData();
      console.log(this.$route.query, 'query----');
    },
    async preSaveWlInfoAction() {
      let res;
      const {
        thumbUrl, gerId, customizeNo, cuserid,
      } = this.$route.query;
      try {
        res = await getPreSaveWlInfo({
          thumbUrl,
          customizeNo,
          outTradeNo: gerId,
        });
      } catch (e) {
        console.log(e);
        this.isLoading = false;
      }
      if (res.code === 1) {
        this.isLoading = false;
        sessionStorage.setItem('isTimes', 1);
      }
    },
    addressActioned(data) {
      if (data.address) {
        let arr = data.address.split('/');
        return arr[0] + '<br/>' + arr[1];
      }
      return '';
    },
    async getDetailsData() {
      let res;
      const { goodsId, cuserid } = this;
      try {
        res = await getQueryGoodsDetail({ goodsId, cuserid });
      } catch (e) {
        console.log(e);
        return this.$toast({
          content: e.desc,
          duration: 3000,
        });
      }
      if (res.code === 1) {
        this.goodsDetails = res.results;
      }
    },
    async saveAddressAction() {
      let res;
      const { cuserid } = this;
      let addressList = sessionStorage.getItem('addressList');
      if (!addressList) {
        return this.$toast({
          content: '请填写收货地址!',
          duration: 3000,
        });
      }
      addressList = JSON.parse(addressList);
      const {
        address, call, mid, receiptname,
      } = addressList;
      let { wid, gerId } = this.$route.query;
      wid = wid || sessionStorage.getItem('wid');
      gerId = gerId || sessionStorage.getItem('gerId');
      console.log(wid, gerId);
      const params = {
        wid,
        recordid: gerId,
        cuserid,
        call,
        address,
        receiptname,
      };
      // console.log(addressList);
      console.log(params);
      try {
        res = await getSaveWinnerAddress(params);
      } catch (e) {
        console.log(e);
        this.isLoading = false;
        return this.$toast({
          content: e.desc,
          duration: 3000,
        });
      }
      if (res.code === 1) {
        this.isLoading = false;
        sessionStorage.setItem('saveAddress', 1);
        this.jumpRecord();
      }
    },
    jumpRecord() {
      this.$router.push({
        path: 'record',
      });
    },
    childOpen() {
      this.isOpenAddress = false;
      location.reload();
    },
  },
  destroyed() {
  },
};
</script>
<style scoped lang="scss">
  .order-page {
    width: 100%;
    height: 100%;
    -webkit-overflow-scrolling: touch;
    position: relative;
    background-color: #f6f6f6;
  }
  .orderAddress {
    background: #ffffff;
    border-bottom: solid 1px #dddddd;
    padding: 0.533333rem 0.8rem 0.693333rem 1.226666rem;
    min-height: 0;
  }
  .orderAddress.no-address::before {
    display: none;
  }
  .addressContent:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0.266666rem;
    transform: translateY(-50%);
    display: inline-block;
    vertical-align: middle;
    width: 0.557rem;
    height: 0.59rem;
    background-size: 100% 100%;
  }
.addressContent {
  // width: 100%;
  // min-height: 2.666666rem;
  // padding: 0.370666rem 0.4rem 0.266666rem 1.226666rem;
  // box-sizing: border-box;
  // -webkit-box-sizing: border-box;
  // -moz-box-sizing: border-box;
  background-color: #fff;
  margin: 0 0.21333rem;
  border-radius: 0.32rem;
  position: relative;
}
  .orderAddress:before {
    background-image: url(./img/position.png);
    width: 0.44rem;
    height: 0.56rem;
    left: 0.4rem !important;
  }
/*.orderAddress:after*/
.orderAddressArrow {
  /*content: "";*/
  width: 0.213333rem;
  height: 0.373333rem;
  // background-image: url('./img/arrow.png');
  background-size: cover;
  right: 0.4rem;
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  img {
    width: 100%;
  }
}
.orderContent {
  background: #ffffff;
  font-size: 0;
  padding: 0.533333rem 0;
  margin: 0.21333rem 0.21333rem;
  border-radius: 0.32rem;
  /* border-bottom: solid 1px #dddddd; */
}
.orderImg {
  display: inline-block;
  vertical-align: middle;
  width: 1.6rem;
  margin-right: 0.266666rem;
}
.orderImg > img {
  width: 100%;
  height: auto;
  display: block;
}
.orderTip {
  padding-left: 0.4rem;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  margin-top: 0.266666rem;
  font-size: 0;
}
.orderTipImg {
  display: inline-block;
  vertical-align: top;
  line-height: 1.2;
  /*width: 0.426666rem;*/
  width: 4.4%;
  margin-right: 2.8%;
  /*height: 0.373333rem;*/
  /*margin-right: 0.266666rem;*/
  /*background-image:url(../img/orderTip.png);*/
  /*background-size: cover;*/
}
.orderTipImg > img {
  width: 100%;
  height: auto;
  display: block;
}
.orderTip > span {
  display: inline-block;
  vertical-align: top;
  line-height: 1.2;
  padding-right: 0.266666rem;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  width: 92.8%;
  font-size: 0.346666rem;
  letter-spacing: -0.3px;
  color: #999999;
}
.line1 {
  overflow: hidden;
  margin-bottom: 0.266666rem;
}
.name {
  /*float: left;*/
  display: inline-block;
  vertical-align: middle;
  font-size: 0.426666rem;
  letter-spacing: -0.4px;
  color: #333333;
  margin-right: 0.8rem;
}
.tel {
  display: inline-block;
  vertical-align: middle;
  /*float: right;*/
  font-size: 0.426666rem;
  letter-spacing: -0.4px;
  color: #333333;
}
.line2 {
  font-size: 0.426666rem;
  letter-spacing: -0.4px;
  color: #333333;
  padding-right: 0.266666rem;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.orderContainer .line2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.operate {
  height: 1.066666rem;
  padding: 0 0.4rem;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  border-top: solid 1px #dddddd;
  overflow: hidden;
}
.btn {
  width: 80%;
  height: 1.17333rem;
  background: linear-gradient(95deg,#ff4a4a,#ff3636);
  border-radius: .58667rem;
  font-size: .45333rem;
  font-weight: 500;
  color: #fff;
  line-height: 1.17333rem;
  text-align: center;
  margin: 60px auto 0;
  &.default {
    background: rgba(0, 0, 0, .3);
  }
}
.orderText {
  display: inline-block;
  vertical-align: middle;
}
.orderYY {
  font-size: 0.32rem;
  font-weight: 500;
  color: #333333;
  margin-bottom: 0.053333rem;
}
.orderTitle {
  font-size: 0.4rem;
  color: #333333;
}
.detailContent {
  margin: 0 0.3rem;
}
.detailContent > p {
  font-size: 0;
  margin-bottom: 0.106666rem;
}
.detailContent > p:last-child {
  margin-bottom: 0;
}
.detailContent > p > span {
  width: 50%;
  display: inline-block;
  vertical-align: middle;
  font-size: 0.346666rem;
  color: #999999;
}
.detailContent > p > span:nth-of-type(2) {
  text-align: right;
}
.detailContent > p > span > span {
  font-size: 0.426666rem;
  color: #ff3636;
  font-weight: 500;
}
.address-write {
  text-align: center;
  font-size: .4rem;
  position: relative;
}
.fixed {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
</style>
